import React, { Component } from "react";
import './about-us.css';
class AboutUS extends Component {
    constructor(props) {
        super(props);

        this.state = {

            emailModal: false,
            modalText: null,
            isLoading: false,
            modalView: false,        };
    }
    render() {
        return (
            <div className="container p-1 pb-5 pt-2">
               
            <div className="justify-content-center col-lg-10 col-md-11 col-sm-12 mx-auto d-flex">
                <div>
                        <h1 className="about-us-title  pl-2">About Ethiopian Holidays</h1>
                        <p className="text-justify p-2 my-1">
                            Ethiopian Holidays, the tourism division of Ethiopian Airlines, is dedicated to showcasing the best travel experiences both within Ethiopia and around the world. As Ethiopian Airlines expanded its global network, Ethiopian Holidays was created to facilitate seamless travel and vacation planning for passengers, whether for domestic or international journeys.
                        </p>
                        <p className="text-justify p-2 my-1">
                            We offer unforgettable travel experiences across Ethiopia—from exploring its rich history to immersing in the vibrant cultures of indigenous tribes, enjoying breathtaking treks, or unwinding in serene retreats. Our diverse packages cater to every interest, whether you're seeking a brief city stopover or an all-inclusive holiday. For those with a taste for adventure, our African Safari tours and thrilling treks in the Simien Mountains or Mount Kilimanjaro are perfect choices.
                        </p>
                        <p className="text-justify p-2 my-1">
                            For special occasions like honeymoons, we provide romantic getaways in stunning destinations such as Seychelles, Zanzibar, and Mombasa. Our expert travel consultants are here to customize any package to ensure your journey is stress-free and tailored to your desires. Experience the warmth of Ethiopian hospitality with Ethiopian Holidays, where your travel dreams become a reality.
                        </p>
                        <p className="text-justify p-2 my-1">
                            Whether your holiday is filled with adventure, nature, history, or a mix of everything, the travel experts at Ethiopian Holidays can arrange unique and captivating trips. We offer flexibility in travel planning, carefully curated itineraries, and a network of top travel specialists and tour operators to ensure each trip is flawless. With experienced guides, excellent accommodations, and reliable transportation, you can relax and enjoy your holiday while Ethiopian Holidays takes care of all the details.
                        </p>
                </div>
            </div>
                </div>
        );
    }
}
export default AboutUS;