import React, { Component } from "react";
import Destination from "./destination/destination";
import "./header.css";
import { Link } from "react-router-dom";
import packageCatagory from "../../../models/package/catagory";
import { HttpService } from "../../../services/http/http-service";
import DrawerToggleButton from "./sidNav/DrawerToggleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, FormControl, Form } from "react-bootstrap";
import { BrowserView, isMobile } from "react-device-detect";
import Search from "@material-ui/icons/Search";
import Autosuggest from "react-autosuggest";

var languages = [];

const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
        ? []
        : languages.filter(
            (lang) => lang.toLowerCase().slice(0, inputLength) === inputValue
        );
};
const renderSuggestion = (suggestion) => (
    <span className="name">{suggestion}</span>
);

const getSuggestionValue = (suggestion) => suggestion;
class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serachPackage: null,
            isLoading: true,
            packageCatagories: [],
            showAlert: true,
            showSearchBox: false,
            value: "",
            suggestions: [],
        };

    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
        });
        // console.log("method",method)
        if (method === "click" || method === "enter") {
            this.search(newValue);
        }
    };
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value),
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };
    async componentDidMount() {

        let pkgCategories = JSON.parse(localStorage.getItem("packageCatagories"));
        if (pkgCategories) {
            this.setState({ packageCatagories: pkgCategories })
        } else {
            this.getPackageCatagories();
        }
        await this.getSerchBox();
        if (!isMobile) {
            await HttpService.getServicePackage("Availability/GetPackageNames")
                .then((response) => {
                    languages = response.data.packageNames;
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
    }
    getSerchBox() {
        // console.log("window.document.location.pathname", window.document.location);
        var searchingPaths = [
            { path: "package-lists" },
            { path: "package-details" },
            { path: "destination" },
            { path: "country" },
            { path: "search-packages" },
            { path: "home" },
        ];

        for (let i = 0; i < searchingPaths.length; i++) {
            if (window.document.location.pathname.includes(searchingPaths[i].path)) {
                // console.log(
                //   "window.document.location.pathname includes",
                //   window.document.location.pathname.includes(searchingPaths[i].path)
                // );
                this.setState({ showSearchBox: true });
            }
        }
        if (window.document.location.pathname == "/") {
            this.setState({ showSearchBox: true });
        }
    }
    search = (searchValue = null) => {
        if (this.state.value !== "" || searchValue !== null) {
            var relativePathUrl = null;
            if (searchValue !== null) {
                relativePathUrl = "/search-packages?search=" + searchValue;
            } else {
                relativePathUrl = "/search-packages?search=" + this.state.value;
            }

            // window.location.replace("search-packages?search="+this.state.serachPackage)
            window.location.replace(relativePathUrl);
        }
    };

    getPackageCatagories() {
        let relativePath = "MasterDatas/GetMasterDatas?SearchType=PACKAGECATEGORY";

        this.setState({ isLoading: true });
        HttpService.getServicePackage(relativePath)
            .then((response) => {
                this.setState({ isLoading: false });
                let responseData = response.data;
                if (response.status === 200) {
                    localStorage.setItem(
                        "packageCatagories",
                        JSON.stringify(responseData.packageCatagories)
                    );
                    this.setState({
                        packageCatagories: responseData.packageCatagories,
                    });
                } else {
                    // console.log("responseData error1 ");
                }
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }
    showPackageLists = (packageTypeId, packageTypeName) => {

        //debugger
        let search =
            "?packageTypeName=" +
            packageTypeName +
            "&packageTypeId=" +
            packageTypeId +
            "&countryName=" +
            null +
            "&countryCode=" +
            null;

        // /54/?countryName=Ethiopia&countryCode=ET&packageTypeId=54&packageTypeName=Nature%20and%20Adventure%20Tours&SearchType=COUNTRYCODE
        let path = "/package-lists/" + packageTypeId + "/" + search;

        window.location.assign(path);
    };
    redirectToHome = () => {
        localStorage.clear();

        window.location.href = "/";
    };
    render() {
        var { packageCatagories } = this.state;
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: "Search Packages",
            value,
            onChange: this.onChange,
            type: "search",
        };
        var alert = null;
        if (this.state.showAlert) {
            alert = (
                <div
                    className="alert alert-warning alert-dismissable"
                    role="alert"
                    style={{ marginBottom: "-3px" }}
                >
                    <span className="fa fa-exclamation-circle">&nbsp;&nbsp;&nbsp;</span>
                    <a href={`${process.env.PUBLIC_URL}/ListofCountries.pdf`} rel="noopener noreferrer" target="_blank" style={{ float: "left", color: "#2E7D32" }}>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        Visa On Arrival</a>
                </div>
            );
        }
        return (
            <div>
                {alert}
                <div className="topHeader">{/* <TopHeader /> */}</div>

                <nav className="navbar navbar-expand-lg navbar-light header-nav">
                    <div className="col-lg-3 col-md-3">
                        <a
                            onClick={() => {
                                this.redirectToHome();
                            }}
                            style={{ cursor: "pointer" }}
                            title="Redirect to home page"
                        >
                            <img
                                className="img-fluid logo-img pr-5 mr-5"
                                alt=""
                                src={window.location.origin + "/assets/image/holidays.png"}
                            />
                        </a>
                    </div>

                    <div className="toolbar__toggle-button" style={{ float: "right" }}>
                        <DrawerToggleButton click={this.props.drawerClickHandler} />
                    </div>

                    <div className="collapse navbar-collapse " id="navbarTogglerDemo01">
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0  ">
                            {/*<Link to="">*/}
                            {/*</Link>*/}
                            {/* to="/holidays-type/Holidays Type" */}
                            <li className="nav-item dropdown dropdown2">
                                <span
                                    className="nav-link dropdown-toggle h5"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Destinations
                                </span>
                                <div
                                    className="dropdown-menu dropdown-menu2 shadow p-0 ml-3 mr-3"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <Destination />
                                </div>
                            </li>
                            {packageCatagories.map((itemtypes, key) => (
                                <Link to="" key={key}>
                                    <li className="nav-item dropdown">
                                        <span
                                            className="nav-link dropdown-toggle h5"
                                            id="navbarDropdownMenuLink"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {itemtypes.name}{" "}
                                        </span>
                                        <div
                                            className="dropdown-menu shadow mt-0 p-0"
                                            aria-labelledby="navbarDropdownMenuLink"
                                            style={{ width: "220px" }}
                                        >
                                            {itemtypes.packageTypes.map((item, key) => (
                                                <span
                                                    key={key}
                                                    className="list-group-item listItem   d-flex "
                                                    onClick={() =>
                                                        this.showPackageLists(item.id, item.name)
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                </Link>
                            ))}
                            {/* <Link to="/trip-planner" className="nav-link h5">
                <li className="nav-item dropdown">Trip Planner</li>
              </Link> */}

                            {/* <Link  className=""> */}
                            <li className="nav-item dropdown h5">
                                <a className="text-dark nav-link" target='_blank' href="https://www.ethiopianairlines.com/aa/book/booking/ethiopian-airlines-conventions">
                                    MICE Packages
                                </a>
                            </li>
                            <li className="nav-item dropdown h5">
                                <a className="text-dark nav-link" target='_blank' href="https://www.ethiopianairlines.com/aa/book/special-deals/medical-travel">
                                    Medical Travel
                                </a>
                            </li>
                            <li className="nav-item dropdown h5">
                                <a className="text-dark nav-link" href="/about-us">
                                    About Us
                                </a>
                            </li>
                            {/* </Link> */}

                            <li className="nav-item dropdown"></li>
                        </ul>
                    </div>
                    {/* {this.state.showSearchBox && !isMobile && (
            <SearchField
              placeholder=" e.g. coffee tour"
              onChange={(e) => {
                console.log("value ", e);
              }}
              onSearchClick={(value) => {
                this.search(value);
              }}
              onEnter={(value) => {
                this.search(value);
              }}
            />
          )} */}
                    {!isMobile && (
                        <div style={{ display: "inline" }}>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                            <button className="search-button" onClick={() => this.search()}>
                                <Search />
                            </button>
                        </div>
                    )}
                </nav>
            </div>
        );
    }
}

export default Header;
